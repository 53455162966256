<template>

    <div class="row gx-3 gy-1 mt-1">
        <div class="col-12">
            <ODataLookup
                :data-object="dsDocsLkp"
                :whereClause="lkpWhereClause"
                reloadOnWhereClauseChange
                :bind="sel => changeDoc(sel)"
                :height="400">
                    <template #target="scope" @click="scope.open">
                        <button class="btn btn-sm btn-outline-primary" :ref="scope.target">
                            <span v-if="props.document_ID">{{$t('Change document')}}</span><span v-else>{{$t('Choose document')}}</span>
                        </button>
                    </template>
                <OColumn name="ID" width="90"></OColumn>
                <OColumn name="Name" width="200"></OColumn>
                <OColumn name="Title" width="250"></OColumn>
                <OColumn name="DocumentType" width="125"></OColumn>
                <OColumn name="Rev" width="75"></OColumn>
                <OColumn name="Status" width="125"></OColumn>
                <OColumn name="OrgUnit" width="200"></OColumn>
                <template #actions>
                    <button class="btn btn-sm btn-link" :title="$t('Toggle')" @click="lkpToggle = !lkpToggle">{{ lkpToggleText }}</button>
                </template>
            </ODataLookup>
        </div>

        <div v-if="props.document_ID && dsDoc.state.isLoaded && dsDoc.current.ID && dsDoc.current.Title" class="col-12 mt-2">
            <span class="h6">{{ dsDoc.current.Title }}</span>
        </div>
        <div v-if="props.document_ID && dsDoc.state.isLoaded && dsDoc.current.ID" class="form-group col-6">
            <label>{{$t('Doc No / Name')}}</label>
            <input class="form-control form-control-sm" :value="dsDoc.current.Name" disabled>
        </div>
        <div v-if="props.document_ID && dsDoc.state.isLoaded && dsDoc.current.ID" class="form-group col-6">
            <label>{{$t('Org. Unit')}}</label>
            <input class="form-control form-control-sm" :value="dsDoc.current.OrgUnit" disabled>
        </div>
        <div v-if="props.document_ID && dsDoc.state.isLoaded && dsDoc.current.ID" class="form-group col-6">
            <label>{{$t('Document Type')}}</label>
            <input class="form-control form-control-sm" :value="dsDoc.current.DocumentType" disabled>
        </div>
        <div v-if="props.document_ID && dsDoc.state.isLoaded && dsDoc.current.ID" class="form-group col-2">
            <label>{{$t('Rev')}}</label>
            <input class="form-control form-control-sm" :value="dsDoc.current.Rev" disabled>
        </div>
        <div v-if="props.document_ID && dsDoc.state.isLoaded && dsDoc.current.ID" class="form-group col-4">
            <label>{{$t('Status')}}</label>
            <input class="form-control form-control-sm" :value="dsDoc.current.Status" disabled>
        </div>

    </div>
</template>

<script setup>
    import { ref, computed, watch, onMounted } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { context } from 'o365-modules';


    const props = defineProps({
        document_ID: Number,
        orgunit_ID: Number

    });

    const emit = defineEmits(["docChanged"]);

    const lkpToggle = ref(false);
    const lkpWhereClause = computed(() => {
        return 'IsLatest = 1 AND Obsolete IS NULL AND Deleted IS NULL AND (Delivered IS NOT NULL OR ToBeDelivered = 0) AND ' + (lkpToggle.value ? `OrgUnitIdPath LIKE '${context.idPath}%'` : `OrgUnit_ID = ${props.orgunit_ID}`)
    });
    const lkpToggleText = computed(() => {
        return lkpToggle.value ? $t("Restrict to folder Org. Unit") : $t("Show all within context")
    });

    const dsDoc = getOrCreateDataObject({
        id: 'dsDoc_DocChooser',
        viewName: "aviw_Arena_Docs",
        //appId: props.dataObject.appId, 
        maxRecords: 1,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "ID", type: "number" },
                {name: "Name", type: "string"},
                {name: "DocumentType", type: "string"},
                {name: "Rev", type: "string"},
                {name: "Title", type: "string"},
                {name: "OrgUnit", type: "string"},
                {name: "Status", type: "string"}
            ]
    }), dsDocsLkp = getOrCreateDataObject({
        id: 'dsDocsLkp_DocChooser',
        viewName: "aviw_Arena_Docs",
        //appId: props.dataObject.appId, 
        maxRecords: 25,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "ID", type: "number" },
                {name: "Name", type: "string"},
                {name: "DocumentType", type: "string"},
                {name: "Rev", type: "string"},
                {name: "Title", type: "string"},
                {name: "OrgUnit", type: "string"},
                {name: "Status", type: "string"}
            ]
    })

    watch(()=>props.document_ID, ()=>{
        loadDoc();
    })

    const changeDoc = (row) => {
        emit("docChanged", row);
        //props.document_ID = row.ID;
    }

    const loadDoc = () =>{
        dsDoc.recordSource.whereClause = props.document_ID ? `ID = ${props.document_ID}` : '1=2'
        dsDoc.load();//.then(()=>console.log(dsDoc.current));
    }

    onMounted(() => {
        loadDoc();
    });


</script>